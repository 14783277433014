<template>
  <div class="cummode" v-if="show">
    <div class="cun_inner">
      <dv-border-box-1>
        <div class="close nclose" @click="close">×</div>
        <div class="main_title" >{{ paramsData.name }}</div>
        <!-- 人物图片 -->
        <div class="box" v-if="paramsData.showType=='peopleImg'">
          <div class="peoples_box">
            <div class="people_item" v-for="(item,index) in peopleList" :key="index" @click="showDetails(item)">
              <div class="peoples_img" :class="paramsData.type=='griders'?'':'item_width'">
                <img style="width:100%;height:100%"  :src="item.avatarUrl" v-if="item.avatarUrl"/>
                <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
              </div>
              <div class="peoples_name">{{item.nickName||item.fullName}}</div>
              <div class="peoples_name" style="padding-top:0">{{item.positionName}}</div>
            </div>
          </div>
          <template  v-if="paramsData.type=='cunMembers'" >
              <div v-for="(item,index) in morePeoples" :key="index">
                <div class="se_title new_cunTitle">{{ item.committeeName }}</div>
              <div class="peoples_box" >
                <div class="people_item" v-for="(item,index) in item.memberList" :key="index" @click="showDetails(item)">
                  <div class="peoples_img" :class="paramsData.type=='griders'?'':'item_width'">
                    <img style="width:100%;height:100%"  :src="item.avatarUrl" v-if="item.avatarUrl"/>
                    <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
                  </div>
                  <div class="peoples_name">{{item.nickName||item.fullName}}</div>
                  <div class="peoples_name" style="padding-top:0">{{item.committeeName}}</div>
                </div>
              </div>
              </div>
             </template>

        </div>
        <!-- 户表格 -->
        <div class="box" v-if="paramsData.showType=='tableList'">
          <div v-for="(item,index) in numsForm" :key="index">
            <div class="se_title">{{ item.areaManagementName }}</div>
              <div class="title flex_center_align flex_center">
                <div class="cun_num flex_colum flex_center">
                  <div>{{ item.familyNum }}</div>
                  <span>户数</span>
                </div>
                <div class="cun_num flex_colum flex_center">
                  <div>{{ item.residentNum }}</div>
                  <span>人口数</span>
                </div>
                <div class="cun_num flex_colum flex_center">
                  <div>{{ item.flowNum }}</div>
                  <span>流动人口数</span>
                </div>
              </div>
              <div class="title">详情</div>
              <div class="table_box">
                <el-table stripe :data="item.familyMaintainList" v-loading="loading" height="500">
                  <el-table-column label="村/组" align="center" prop="managementFull" />
                  <el-table-column label="门牌号/商铺名" align="center" prop="storehouseNumber" width="120" />
                  <el-table-column label="户主" align="center" prop="famName" />
                  <el-table-column label="流动人口" align="center" prop="famCountWai" />
                  <el-table-column label="总人口" align="center" prop="famCount" />
                  <el-table-column label="" align="center" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                      <img src="../assets/img/r-arrow.png" alt="" @click.stop="handleShow(scope.row)" style="width:30px;height:30px;cursor: pointer;" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
        </div>
        <!-- 简介 -->
        <div class="box" v-if="paramsData.showType=='description'">
          <div class="inclu">
          <!-- 总人口 -->
          <template v-if="paramsData.type=='residentNum'">
              建平县万寿街道办事处小平房村
              小平房村共有七个自然屯，十三个村民组，833户，总人口3307人。其中常住人口2418人、外来人口33人、流动人口856
              2007年，小平房全村工农业总产值达到1.5亿元，上缴税金2400万元，集体经济收入1800万元，农民人均纯收入达到7500元。
              2018年10月8日，经地方推荐和专家审核，农业农村部拟将小平房村推介为2018年中国美丽休闲乡村。
              2019年12月24日，入选全国乡村治理示范村名单。 12月25日，入选第一批国家森林乡村名单。
              小平房村被誉为“辽西第一村”的省级文明村――建平县万寿街道办事处小平房村，在建设生态村的过程中，按照社会主义新农村建设的总体要求，走出了一条“依靠资源强工业，依靠工业哺农业”的新农村建设路子，在科技兴村的建设中迈出了坚实的步伐。
          </template>
             <!-- 常驻人口 -->
          <template v-if="paramsData.type=='oftenNum'">
              建平县万寿街道办事处小平房村
              常住人口共有2418名
              万寿街道小平房村持续推进农业产业结构调整，引导当地常住人口因地制宜发展特色农业，高标准建设设施果蔬大棚，种植高品质蔬菜和水果。目前，该村有设施农业暖棚40余栋、3000余延长米。为了持续助农增收，该村积极做好服务指导，帮助农户协调解决各种生产问题。结合市场发展趋势，按照果蔬的不同收获时节，引导村民有计划错茬错时种植各类果蔬，并为农户提供产销对接服务。
              在发展设施农业的同时，小平房村将宜居宜业宜游作为全新定位，结合乡村旅游，建设农业观光带、果蔬采摘园，发展生态观光旅游产业。依托丰富的林地优势，通过土地流转、反租倒包、轧沟造地等多种方式，修建高标准果树台田，建设5000亩南果梨园。依托天秀山森林公园，完善特色民宿、风情农家院等配套设施，全面打造集文化普及、旅游观光、田园体验、参观考察、休闲娱乐、民宿度假于一体的乡村旅游综合体。果蔬飘香满村好风景，吸引着游客纷至沓来。
          </template>
           <!-- 外来人口 -->
          <template v-if="paramsData.type=='foreignNum'">
            建平县万寿街道办事处小平房村
            外来人口共有33名
            经济的振兴激发着新业态的不断成型，小平房村将“宜居”“宜业”“宜游”作为全新定位，将文化旅游业确定为发展的新道路。充分结合第一产业特色，建设以农业观光带、果蔬采摘园为主的生态旅游产业，同时依托天秀山森林公园，完善特色民宿、风情农家院等配套设施，全面形成集文化普及、旅游观光、田园体验、参观考察、休闲娱乐、民宿度假于一体的乡村旅游综合体。
            乡村的美好不仅体现于外在，更在百姓的心里。这每一处风景不仅体现着小平房人卓绝的“绣花功夫”，更是该村全面加强村级治理体系建设后展现出的健康肌理。生态宜居、乡风文明，让这里不仅有了“全国文明村镇”的“面子”，更有了“全国乡村治理示范村”这一稠密厚实的“里子”逐级的吸引着外来人口的引入。
          </template>
           <!-- 流动人口 -->
          <template v-if="paramsData.type=='flowNum'">
            建平县万寿街道办事处小平房村
            流动人口共有856名
            近年来，小平房村聚焦美丽宜居乡村建设，统一规划建设二层小楼，每座建筑面积在160平方米至340平方米。入住的村民每平方米出资600元至800元不等，户均得到集体建房补助20万元，小平房村95%的村民住进了楼房，后合并过来的原石灰窑子村60%以上的村民住进了标准的“北京平”。现在，村里每年为考上大学的学生发放奖学金；村里建有养老院，每年为60岁以上老人发放养老金；每年春节为全体村民发放福利，为全体村民缴纳医疗保险，村民的幸福指数不断攀升，也促使了更多流动人口流入了村中建设。
            党的二十大报告提出，必须坚持在发展中保障和改善民生，鼓励共同奋斗创造美好生活，不断实现人民对美好生活的向往。我们将坚持发展集体经济，走共同富裕的道路不动摇，坚持生态优先、绿色发展的理念，建设宜居、宜业、宜游的社会主义新农村，让村民享受到更多实实在在的发展成果，走上共同富裕之路。
          </template>
          </div>
        </div>
        <!-- 党组织架构 -->
        <div class="box" v-if="paramsData.showType=='partyorganize'">
          <div class="flex_center_align" style="width: 80%;margin: 0 auto;">
            <div class="party_left">
              组织架构
            </div>
            <!-- {{peopleList}} -->
            <div class="party_right">
              <div class="party_right_item" v-for="(item,index) in peopleList" :key="index">
                <div class="party_right_item_center" @click="partyfunction(item)">
                  <div class="party_right_title">{{item.branchName}}</div>
                  <div class="party_right_title" style="margin:0">{{item.memberNums||0}}人</div>
                  <div class="party_right_shu">支部书记</div>
                  <div class="party_right_shu"  style="margin:0">{{item.secretaryName}}</div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <!-- 党员基本信息表格 -->
        <div class="box" v-if="paramsData.showType=='partybase'">
        <!-- 党员基本情况 -->
          <div style="width: 80%;margin: 0 auto;">
            <table border="" class="table tc" style="margin-top: 10px">
              <tbody>
                <tr>
                  <th rowspan="2">党员总数</th>
                  <th rowspan="2">124</th>
                  <th>男</th>
                  <th>92</th>
                  <th>73.98%</th>
                </tr>
                <tr>
                  <th>女</th>
                  <th>32</th>
                  <th>26.02%</th>
                </tr>
                <tr>
                  <th colspan="2">30周岁以下</th>
                  <th colspan="2">6</th>
                  <th>4.87%</th>
                </tr>
                <tr>
                  <th colspan="2">31-44周岁</th>
                  <th colspan="2">36</th>
                  <th>29.26%</th>
                </tr>
                <tr>
                  <th colspan="2">45-59周岁</th>
                  <th colspan="2">29</th>
                  <th>23.57%</th>
                </tr>
                <tr>
                  <th colspan="2">60周岁以上</th>
                  <th colspan="2">48</th>
                  <th>42.27%</th>
                </tr>

                <!-- <tr class="tx_Font" v-for="(item,index) in tables" :key="index">
                  <td colspan="2" class="valueTitle">{{item.dtlName}}</td>
                  <td colspan="2" class="valueTitle">{{item.curIndex}}</td>
                  <td colspan="2" class="valueTitle">{{item.curTexture == 0 ? '纸币': '硬币'}}</td>
                  <td colspan="2" class="valueTitle">{{item.curValue}}</td>
                  <td colspan="4" class="valueTitle">{{item.num}}</td>
                  <td colspan="4" class="valueTitle">{{item.untName}}</td>
                  <td colspan="2" class="valueTitle">{{item.nums|numsFormat}}</td>
                  <td colspan="2" class="valueTitle">{{item.capital}}</td>
                </tr> -->
              </tbody>
            </table>
          </div>
            <div style="width: 80%;margin: 0 auto;">
              <div class="se_title new_cunTitle">50周年党员</div>
              <table border="" class="table tc" style="margin-top: 10px">
            <thead>
            <tr>
                    <th>姓名</th>
                    <th>入党日期</th>
                    <th>所在党支部</th>
                  </tr>
            </thead>
                <tbody>
                  <tr v-for="(item,index) in partyList" :key="index">
                    <th>{{item.name}}</th>
                    <th>{{item.date}}</th>
                    <th>{{item.memeber}}</th>
                  </tr>
                </tbody>
              </table>
          </div>
           <template>
              <div v-for="(item,index) in morePeoples" :key="index">
                <div class="se_title new_cunTitle">{{ item.branchName }}</div>
              <div class="peoples_box" style="justify-content: space-evenly" >
                <div class="people_item" v-for="(item,index) in item.memberList" :key="index" @click="showDetails(item)">
                  <div class="peoples_img" :class="paramsData.type=='griders'?'':'item_width'">
                    <img style="width:100%;height:100%"  :src="item.avatarUrl" v-if="item.avatarUrl"/>
                    <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
                  </div>
                  <div class="peoples_name">{{item.memberName}}</div>
                </div>
              </div>
              </div>
             </template>
        </div>



      </dv-border-box-1>
    </div>

    <!-- 简介 -->
    <div class="cummode" v-if="itemShow" style="z-index:1000">
     <div class="cun_inner" >
      <dv-border-box-1>
        <div class="close nclose" @click="close('item')">×</div>
        <div class="main_title" >{{ itemData.nickName||itemData.fullName }}简介</div>
        <div class="box">
        <div class="details_box">
          <div class="people_item" style="width: 14.5%;margin: 0 auto;">
            <img style="width:100%;height:100%"  :src="itemData.avatarUrl" v-if="itemData.avatarUrl"/>
            <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
            <div class="peoples_name">{{itemData.nickName||itemData.fullName}}</div>
          </div>
          <div class="people_inc">
          {{itemData.remarks}}
          </div>
          <div>

          </div>
        </div>
        </div>
      </dv-border-box-1>
      </div>
    </div>

    <!-- 组织架构详情 -->
    <div class="cummode" v-if="partyShow" style="z-index:1000">
     <div class="cun_inner" >
      <dv-border-box-1>
        <div class="close nclose" @click="close('party')">×</div>
        <!-- <div class="main_title" >{{ itemData.memberName }}</div> -->
        <div class="box">
         <div class="table_box">
              <el-table stripe :data="partyData" v-loading="loading" height=" 500">
                <el-table-column label="姓名" align="center" prop="memberName" />
                <el-table-column label="性别" align="center" prop="memberSex" :formatter="sexFormat"/>
                <el-table-column label="职位" align="center" prop="positionName" width="120" />
                <el-table-column label="简介" align="center" prop="remarks" />
              </el-table>
            </div>
        </div>
      </dv-border-box-1>
      </div>
    </div>


    <wg-mode ref="wgMode"></wg-mode>
  </div>
</template>

<script>
import WgMode from './wgMode.vue'
import { getAllGrids, getAllMembers, getResidents, getResidentDetail, getAllPartys, getAllPartyMembers, getAllPartyTable, getAllForests, getAllCommittee, getAllPartyer } from '@/api/api.js'
import { Loading } from 'element-ui'
// import { url } from 'inspector'
export default {
  components: { WgMode },
  data() {
    return {
      show: false,
      itemShow: false,
      peopleList: [],
      paramsData: {},
      loading: true,
      itemData: {},
      numsForm: [],
      partyShow: false,
      partyData: [],
      tables: { cpu: { cpuNum: 0, used: 0, sys: 0, free: 0 } },
      morePeoples: [],
      partyList: [
        { name: '钱如恒', date: '1947.05.01', memeber: '河南党支部' },
        { name: '潘爽', date: '1963.06.01', memeber: '愁水坑党支部' },
        { name: '潘合', date: '1968.01.01', memeber: '愁水坑党支部' },
        { name: '刘荣', date: '1968.07.01', memeber: '愁水坑党支部' },
        { name: '李凤珍', date: '1968.07.01', memeber: '愁水坑党支部' },
        { name: '邹吉福', date: '1968.11.01', memeber: '河北党支部' },
        { name: '邹吉全', date: '1968.11.01', memeber: '河北党支部' },
        { name: '梁玉祥', date: '1969.03.01', memeber: '河北党支部' },
        { name: '梁广江', date: '1969.05.01', memeber: '河北党支部' },
        { name: '李开友', date: '1969.05.01', memeber: '河南党支部' },
        { name: '马凤英', date: '1969.07.01', memeber: '河南党支部' },
        { name: '孙玉彩', date: '1970.03.01', memeber: '石灰窑子党支部' },
        { name: '任永军', date: '1970.06.01', memeber: '石灰窑子党支部' },
        { name: '王  林', date: '1972.12.07', memeber: '石灰窑子党支部' },
        { name: '李殿生', date: '1973.04.10', memeber: '石灰窑子党支部' }
      ]
    }
  },
  methods: {
    sexFormat(row, column) {
      if (row.memberSex == '1') {
        return '男'
      } else {
        return '女'
      }
    },
    initData(data) {
      console.log('====================>', data);
      console.info('zujian', data)

      this.loading = true
      this.paramsData = data
      //网格员
      if (data?.type && data.type === 'griders') {
        this.getallResident(data.regionId)
      }
      if (data.type === 'residentNum') {
        // this.peopleList = [{name:'孙国臣',url:require(url:'@/ass')}]
      }
      //村委成员
      if (data.type === 'cunMembers') {
        this.getMembers()
        // if(){
        this.getCommittee(data.regionId)
        // }
      }
      //总户数 --展示列表
      if (data.type === 'familyNum') {
        this.getallHu(data.regionId)
      }
      if (data.type === 'partyorganize') {
        this.getPartys(data.regionId)
      }
      //党员基本信息表格
      if (data.type === 'partybase') {
        this.getPartyTable(data.regionId)
      }
      // 护林员
      if (data.type == 'rangerNum') {
        this.getFore('forest')
      }
      //森林防火领导组
      if (data.type == 'forestPreNum') {
        this.getFore('forestPre')
      }
      //应急灭火队
      if (data.type == 'impatientNum') {
        this.getFore('impatient')
      }
      this.loading = false

      this.show = true
      // this.tableList = data.tableList;
    },
    close(type) {
      if (type == 'item') {
        this.itemShow = false
        this.itemData = {}
      } else if (type == 'party') {
        this.partyShow = false
        this.partyData = []
      } else {
        this.show = false
        this.numsForm = []
        this.peopleList = []
        this.paramsData = {}
      }
    },
    handleShow(event) {
      getResidentDetail({ familyId: event.id }).then(res => {
        if (res.code == 0) {
          if (res.model && res.model.length) {
            this.$refs.wgMode.initData(event)
            this.$refs.wgMode.wgList = res.model
          } else {
            this.$message.error('当前户无人居住')
          }
        }
      })
    },

    getallResident(regionId) {
      this.peopleList = []
      this.loading = true
      getAllGrids({ regionId: regionId }).then(res => {
        if (res.code == '0') {
          res.model.map(item => {
            if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
          })
          this.peopleList = res.model
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    //人员详情
    showDetails(item) {
      if (!item.remarks) {
        return
      }
      this.itemShow = true
      this.itemData = item
    },
    //村委班子
    getMembers() {
      this.peopleList = []
      getAllMembers({ partyFlag: 1 }).then(res => {
        if (res.code == '0') {
          res.model.forEach(item => {
            if (item.memberName) item.nickName = item.memberName
            if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
          })
          this.peopleList = res.model
          console.log(this.peopleList)
        }
      })
    },
    getCommittee(id) {
      getAllCommittee({ villageId: id }).then(res => {
        if (res.code == '0') {
          res.model.forEach(item => {
            if (item.memberList.length) {
              item.memberList.map(itemData => {
                if (itemData.rangerName) itemData.nickName = itemData.rangerName
                if (itemData.imgUrl) itemData.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + itemData.imgUrl
              })
            }
          })
          this.morePeoples = res.model
        }
      })
    },
    getallHu(villageId) {
      this.loading = true
      this.numsForm = []
      getResidents({ villageId: villageId, villageIds: villageId }).then(res => {
        if (res.code == '0') {
          console.log(res.model)
          if (res.model.length) {
            res.model.map(resmode => {
              if (resmode.familyMaintainList.length) {
                resmode.familyMaintainList.map(i => {
                  i.managementFull = i.managementName + i.houseNumber
                  i.storehouseNumber = i?.store ? i.store : i.houseNumber
                })
              }
            })
          }
          this.numsForm = res.model
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },

    handleShow(event) {
      getResidentDetail({ familyId: event.id }).then(res => {
        if (res.code == 0) {
          if (res.model && res.model.length) {
            this.$refs.wgMode.initData(event)
            this.$refs.wgMode.wgList = res.model
          } else {
            this.$message.error('当前户无人居住')
          }
        }
      })
    },
    //党组织架构
    getPartys(villageId) {
      this.peopleList = []
      getAllPartys({ villageId: villageId }).then(res => {
        if (res.code == '0') {
          this.peopleList = res.model
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    partyfunction(item) {
      if (!item.memberNums || item.memberNums == 0) {
        return
      }
      getAllPartyMembers({ branchId: item.id }).then(res => {
        if (res.code == '0') {
          this.partyData = res.model
          this.partyShow = true
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    getPartyTable(villageId) {
      // getAllPartyTable({ villageId: villageId }).then(res => {

      // })
      this.morePeoples = []
      getAllPartyer({ memberType: 5 }).then(res => {
        if (res.code == '0') {
          if (res.model.length) {
            res.model.forEach(item => {
              if (item.memberList.length) {
                item.memberList.map(itemData => {
                  if (itemData.avatarUrl) itemData.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + itemData.avatarUrl
                  if (itemData.memberName) itemData.nickName = itemData.memberName
                })
              }
            })
          }
          this.morePeoples = res.model
        }
      })
    },
    getFore(type) {
      this.peopleList = []
      getAllForests({ rangerType: type }).then(res => {
        if (res.code == '0') {
          if (res.model.length) {
            res.model.forEach(item => {
              if (item.imgUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.imgUrl
              if (item.rangerName) item.nickName = item.rangerName
            })
          }
          this.peopleList = res.model
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cummode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;
  .cun_inner {
    width: 90%;
    background: rgba(37, 37, 39, 0.8);
    position: absolute;
    left: 5%;
    top: 10%;
    z-index: 1000;
    padding: 20px 10px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes small-to-big {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
    .main_title {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
    }
    .box {
      width: 90%;
      padding: 0 20px;
      max-height: 700px;
      overflow-y: scroll;
      margin: 30px auto;
      .party_nums {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 8px auto;
        .party_item {
          flex: 1;
        }
        .party_title {
          display: inline-block;
          width: 58%;
          text-align: right;
        }
      }
      .new_title {
        padding: 10px 0;
        font-weight: 800;
        margin: 20px auto 0;
      }
      .word {
        font-size: 16px;
        color: #fff;
        line-height: 30px;
        // text-indent: 2em;
        video {
          width: 180px;
          height: 180px;
        }
        img {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
        }
      }
      .word /deep/ img {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ video {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ table {
        width: 100% !important;
        box-sizing: border-box !important;
        border-right: 1px solid grey !important;
        border-bottom: 1px solid grey !important;
        border-spacing: 0px !important;
        tbody {
          border-spacing: 0px !important;
        }
        tbody tr:nth-child(1) {
          text-align: center !important;
        }
        th {
          padding: 4px !important;
          font-size: 15px !important;
          font-weight: bold !important;
          border: 1px solid grey !important;
        }
        tr {
          border-color: inherit !important;
          border: 1px solid grey !important;
        }
        td {
          padding: 4px !important;
          font-size: 14px !important;
          border-top: 1px solid grey !important;
          border-left: 1px solid grey !important;
          text-align: center !important;
        }
      }

      .imgbox {
        margin-top: 20px;
        flex-wrap: wrap;
        .video {
          width: 100%;
          height: 100%;
        }
        .img_item {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .peoples_box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: space-between;
        .people_item {
          width: 33.3%;
          text-align: center;
        }
        .peoples_img {
          width: 450px;
          height: 296px;
          margin: 0 auto;
          display: block;
          // box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 0.7);
        }
        .item_width {
          width: 250px;
          height: 350px;
        }
        .peoples_name {
          font-size: 20px;
          padding: 15px 0;
        }
      }
      .details_box {
        display: flex;
        align-items: flex-start;
        .people_item {
          width: 20.5%;
          text-align: center;
        }
        .peoples_img {
          width: 96%;
          max-height: 430px;
          margin: 0 auto;
          display: block;
        }
        .peoples_name {
          font-size: 22px;
          padding: 15px 0;
        }
        .people_inc {
          margin: 0 auto;
          width: 70%;
          font-size: 20px;
          line-height: 32px;
        }
      }
      .party_left {
        writing-mode: tb-rl;
        letter-spacing: 40px;
        font-size: 40px;
        height: 620px;
        text-align: center;
        background: #cd1010;
        border-radius: 20px;
        border: 4px solid #fff;
        padding: 0 5px;
      }
      .party_right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        &_item {
          width: 230px;
          height: 400px;
          position: relative;
          background-image: url('../assets/img/zubg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin: 15px 25px;
          cursor: pointer;
          &_center {
            background: #fff;
            width: 200px;
            height: 230px;
            margin: auto;
            position: absolute;
            left: 50%;
            margin-left: -105px;
            top: 50%;
            margin-top: -100px;
          }
        }
        &_title {
          color: #cd1010;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin: 15px 0;
          letter-spacing: 5px;
        }
        &_shu {
          color: #131212;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin: 15spx 0;
          letter-spacing: 5px;
        }
      }
    }
    .inclu {
      font-size: 18px;
      letter-spacing: 3px;
      text-indent: 25px;
      line-height: 40px;
      padding: 0 0 30px;
    }
    .title {
      font-size: 22px;
      color: #fff;
      text-align: left;
      padding: 10px 20px;
      .cun_num {
        flex: 1;
        text-align: center;
      }
    }
    .table_box {
      height: auto;
      padding: 0 10px 20px;
    }
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 10000;
      font-size: 29px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
    }
    .table {
      width: 80%;
      margin: 0 auto;
      font-size: 24px;
      border-collapse: collapse;
      th,
      td {
        height: 22px;
        border: 1px solid rgb(206, 199, 199);
      }
    }
    .nclose {
      font-size: 42px;
      right: 25px;
      top: 15px;
    }
  }
  .new_cunTitle {
    margin: 100px 0px 50px;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
  }
}
.box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}
.box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
</style>
<style lang="scss">
.el-table {
  background-color: transparent !important;
}
.el-table th.el-table__cell,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}
.el-table tr {
  background-color: transparent !important;
}
.el-table .cell {
  color: #fff;
}
.el-table th.el-table__cell > .cell {
  color: #fff;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}
</style>
